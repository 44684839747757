import {
  CreateProposalRequest,
  GetProposalListApiRequest,
  GradeProposalRequest,
} from "../interfaces/proposal/req";
import { GetProposalListApiResponse } from "../interfaces/proposal/res";
import { apiRequest } from "../utils/apiConfig";
import { constructFormData } from "../utils/apiUtil";

export const getProposalListApi = async (
  params: GetProposalListApiRequest
): Promise<GetProposalListApiResponse> => {
  const { data } = await apiRequest.get("/proposals", { params });

  return data;
};

export const createProposalApi = async (
  params: CreateProposalRequest
): Promise<void> => {
  const formData = constructFormData(params);

  const { data } = await apiRequest.post("/proposals", formData);

  return data;
};

export const gradeProposalApi = async (
  params: GradeProposalRequest
): Promise<void> => {
  const { data } = await apiRequest.post("/proposals/grade", params);

  return data;
};
