export const constructFormData = <T extends Object>(params: T) => {
  const formData = new FormData();

  Object.keys(params).forEach((key) => {
    const paramsKey = key as keyof T;

    // dont include if value == undefined
    if (params[paramsKey] === undefined) return;

    if (typeof params[paramsKey] === "string") {
      formData.append(key, params[paramsKey] as string);
    } else if (params[paramsKey] instanceof File) {
      params[paramsKey] !== undefined &&
        formData.append(key, params[paramsKey] as File);
    } else if (
      (params[paramsKey] as unknown[])?.length > 0 &&
      (params[paramsKey] as unknown[]).some((p) => p instanceof File)
    ) {
      for (const item of params[paramsKey] as File[]) {
        formData.append(key, item ?? new File([], "IGNORE"));
      }
    } else {
      formData.append(key, JSON.stringify(params[paramsKey]));
    }
  });

  return formData;
};
