import { Box, Button, Typography, TextField, IconButton } from "@mui/material";
import { UseForm } from "../../../hooks/useForm";
import { GroupFormInput } from "../../../interfaces/group/fe";
import { CloseOutlined } from "@mui/icons-material";

type GroupFormProps = Pick<UseForm<GroupFormInput>, "values" | "setValues"> & {
  errorText: string;
};

const GroupForm = (props: GroupFormProps) => {
  const { values, setValues, errorText } = props;

  const handleAddNewMember = () => {
    setValues((oldVal) => ({
      ...oldVal,
      members: {
        ...oldVal.members,
        [Object.keys(oldVal.members).length]: { name: "", gender: "" },
      },
    }));
  };

  const handleRemoveMember = (index: number) => () => {
    const members = { ...values.members };
    delete members[index];

    setValues((oldVal) => ({
      ...oldVal,
      members,
    }));
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, py: 1 }}>
        <TextField
          label="Group Name"
          value={values.name}
          onChange={(event) => {
            setValues((oldValues) => ({
              ...oldValues,
              name: event.target.value,
            }));
          }}
        />

        {Object.values(values.members).map((member, i, arr) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography
                fontWeight="bold"
                display="flex"
                alignItems="center"
                gap={1}
              >
                {arr.length > 1 && (
                  <IconButton onClick={handleRemoveMember(i)}>
                    <CloseOutlined fontSize="small" />
                  </IconButton>
                )}
                Member {i + 1}
              </Typography>
              <TextField
                label="Name"
                value={member.name}
                fullWidth
                onChange={(event) => {
                  setValues((oldValues) => ({
                    ...oldValues,
                    members: {
                      ...oldValues.members,
                      [i]: {
                        ...oldValues.members[i],
                        name: event.target.value,
                      },
                    },
                  }));
                }}
              />

              <TextField
                label="Satuan Kerja"
                value={member.workUnit}
                fullWidth
                onChange={(event) => {
                  setValues((oldValues) => ({
                    ...oldValues,
                    members: {
                      ...oldValues.members,
                      [i]: {
                        ...oldValues.members[i],
                        workUnit: event.target.value,
                      },
                    },
                  }));
                }}
              />
            </Box>
          );
        })}

        {Object.keys(values.members).length < 5 && (
          <Button onClick={handleAddNewMember}>Add New Member</Button>
        )}
      </Box>

      {errorText && (
        <Typography variant="caption" color="error">
          {errorText}
        </Typography>
      )}
    </>
  );
};

export default GroupForm;
