import React, { useState } from "react";

export interface FormValidation<T> {
  isValid: boolean;
  errors: FormInputErrors<T>;
}

export type FormInputErrors<T> = {
  [Key in keyof T]: string;
};

export interface UseForm<T extends object> {
  values: T;
  handleInputChange: (event: any) => void;
  setValues: React.Dispatch<React.SetStateAction<T>>;
  clearValues: () => void;

  inputErrors: FormInputErrors<T>;
  setInputErrors: React.Dispatch<React.SetStateAction<FormInputErrors<T>>>;
  clearInputErrors: () => void;

  resetForm: () => void;
}

export const constructInputErrorsInitialValue = <T extends object>(
  initialValues: T,
  additionalProperties?: { [Key in keyof T]?: string }
): FormInputErrors<T> => {
  let properties = {};
  Object.keys(initialValues).forEach((key) => {
    properties = {
      ...properties,
      [key]: undefined,
    };
  });

  if (additionalProperties) {
    Object.keys(additionalProperties).forEach((key) => {
      properties = {
        ...properties,
        [key]: additionalProperties[key as keyof T],
      };
    });
  }

  return Object.assign({}, properties) as FormInputErrors<T>;
};

export const validateForm = <T extends object>(errors: T): boolean => {
  const isNotValid = Object.values(errors).some((error) => {
    return !!error;
  });

  return !isNotValid;
};

const useForm = <T extends object>(initialValues: T): UseForm<T> => {
  const [values, setValues] = useState<T>(initialValues);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const clearValues = (): void => {
    setValues(initialValues);
  };

  const inputErrorsInitialValue: FormInputErrors<T> =
    constructInputErrorsInitialValue(initialValues);

  const [inputErrors, setInputErrors] = useState<FormInputErrors<T>>(
    inputErrorsInitialValue
  );

  const clearInputErrors = (): void => {
    setInputErrors(inputErrorsInitialValue);
  };

  const resetForm = () => {
    clearValues();
    clearInputErrors();
  };

  return {
    values,
    setValues,
    clearValues,
    handleInputChange,

    inputErrors,
    setInputErrors,
    clearInputErrors,

    resetForm,
  };
};

export default useForm;
