export const PS_PT: Record<string, string> = {
  "PS 1":
    "Memperkuat efektivitas kebijakan moneter dan bauran kebijakan Bank Indonesia untuk mencapai stabilitas nilai Rupiah.",
  "PS 2":
    "Memperkuat sinergi kebijakan Bank Indonesia dengan kebijakan fiskal dan reformasi struktural Pemerintah untuk mendorong pertumbuhan ekonomi yang berkelanjutan.",
  "PS 3":
    "Memperkuat kebijakan dan surveilans makroprudensial untuk mendorong intermediasi, mendukung ketahanan sistem keuangan, memperkuat inklusi ekonomi dan keuangan, serta perlindungan konsumen dalam rangka turut menjaga stabilitas sistem keuangan.",
  "PS 4":
    "Memperkuat koordinasi dan sinergi kebijakan serta pengawasan makroprudensial dengan otoritas terkait untuk mendorong intermediasi, mendukung ketahanan sistem keuangan, memperkuat inklusi ekonomi dan keuangan, serta perlindungan konsumen dalam rangka turut menjaga stabilitas sistem keuangan.",
  "PS 5":
    "Memperkuat kebijakan Sistem Pembayaran-Pengelolaan Uang Rupiah (SP-PUR) untuk percepatan ekonomi dan keuangan digital, serta tersedianya uang layak edar di Negara Kesatuan Republik Indonesia (NKRI).",
  "PS 6":
    "Memperkuat sinergi kebijakan BI dengan kebijakan Pemerintah dan Otoritas Jasa Keuangan (OJK) untuk mengembangkan ekonomi dan keuangan digital.",
  "PS 7":
    "Mengakselerasi pengembangan pasar uang untuk memperkuat efektivitas transmisi kebijakan BI dan sinergi pembiayaan ekonomi.",
  "PS 8":
    "Mengembangkan kebijakan BI yang bersinergi dengan kebijakan Komite Nasional Ekonomi dan Keuangan Syariah (KNEKS) dan pihak lainnya untuk mendukung pengembangan ekonomi dan keuangan syariah.",
  "PS 9":
    "Memperkuat efektivitas kerja sama internasional untuk mendukung kebijakan BI di bidang moneter, makroprudensial, SP-PUR, dan bidang lainnya dalam mencapai stabilitas makroekonomi dan sistem keuangan serta memperjuangkan kepentingan BI dan/ atau ekonomi Indonesia.",
  "PS 10":
    "Memperkuat Efektivitas Kebijakan Kelembagaan Guna Mencapai Kinerja Unggul untuk Mendukung Kredibilitas BI.",
  "PS 11":
    "Meningkatkan Kinerja Efisien Melalui Pengelolaan Organisasi dan Proses Kerja, Sumber Daya Manusia, Keuangan dan Sustainabilitas Keuangan BI.",
  "PS 12":
    "Meningkatkan kinerja efisien organisasi melalui Sistem Informasi (SI) yang unggul, logistik yang prima, dan pengadaan yang kredibel.",
  "PT 1": "Transformasi Digital perumusan bauran kebijakan utama.",
  "PT 2": "Transformasi Digital pelaksanaan bauran kebijakan utama.",
  "PT 3": "Digitalisasi sistem pembayaran Indonesia - BSPI Next Phase.",
  "PT 4": "Transformasi Digital perumusan bauran kebijakan kelembagaan.",
  "PT 5": "Transformasi pengelolaan aset (Sistem Informasi dan Fisik).",
  "PT 6": "Program Pengembangan suksesi kepemimpinan profesional.",
};
