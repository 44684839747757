import { Box } from "@mui/material";
import Sidebar from "../commons/Sidebar";
import DefaultAppBar, { DefaultAppBarProps } from "../commons/DefaultAppBar";

interface SideBarLayoutProps extends DefaultAppBarProps {
  children: React.ReactNode;
}

const SideBarLayout: React.FC<SideBarLayoutProps> = ({
  children,
  pageTitle,
  showBackButton,
}) => {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Sidebar />
      <Box sx={{ width: "100%", maxHeight: "100vh", overflow: "scroll" }}>
        <DefaultAppBar pageTitle={pageTitle} showBackButton={showBackButton} />
        {children}
      </Box>
    </Box>
  );
};

const withSidebarLayout = (
  PageComponent: React.FC,
  props: DefaultAppBarProps
) => {
  return () => (
    <SideBarLayout {...props}>
      <PageComponent />
    </SideBarLayout>
  );
};

export default withSidebarLayout;
