import {
  Box,
  Button,
  LinearProgress,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import withSidebarLayout from "../../components/layout/withSidebarLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTable } from "../../hooks/useTable";
import Dialog from "../../components/commons/Dialog";
import useForm from "../../hooks/useForm";
import { decodeApiError } from "../../utils/decodeApiError";
import { useFetchState } from "../../hooks/useFetchState";
import { useApiRequest } from "../../hooks/useApiRequest";
import GroupListTable from "../../components/pages/Groups/GroupListTable";
import {
  createGroupApi,
  exportGroupsApi,
  getGroupListApi,
  updateGroupApi,
} from "../../api/groups";
import GroupForm from "../../components/pages/Groups/GroupForm";
import { GROUP_FORM_INITIAL_STATE } from "../../constants/group";
import { ChevronRight } from "@mui/icons-material";
import { useSortation } from "../../hooks/useSortation";
import {
  checkCompetitionHasBeenGradedApi,
  getCompetitionApi,
} from "../../api/competition";
import { useParams } from "react-router-dom";
import { ProposalSubmitStatus } from "../../constants/proposalStatus";
import { gradeProposalApi } from "../../api/proposals";

const SORT_MENU: {
  label: string;
  sortBy: string;
  sortOrder: "asc" | "desc";
}[] = [
  {
    label: "Name (A-Z)",
    sortBy: "name",
    sortOrder: "asc",
  },
  {
    label: "Name (Z-A)",
    sortBy: "name",
    sortOrder: "desc",
  },
  {
    label: "Score (A-Z)",
    sortBy: "score",
    sortOrder: "asc",
  },
  {
    label: "Score (Z-A)",
    sortBy: "score",
    sortOrder: "desc",
  },
];

const GroupPage = () => {
  const params = useParams<{ id: string }>();

  const { res: competitionRes, fetch: fetchCompetition } =
    useFetchState(getCompetitionApi);
  const { res: hasBeenGraded, fetch: checkCompetitionHasBeenGradedApiRequest } =
    useFetchState(checkCompetitionHasBeenGradedApi);

  const { res, loading, error, fetch } = useFetchState(getGroupListApi);
  const [searchedName, setSeachedName] = useState("");

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openGradingDialog, setOpenGradingDialog] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSortMenu = Boolean(anchorEl);

  const {
    page,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTable();

  const { sortBy, sortOrder, handleSortationOnClick } = useSortation({
    defaultSortBy: "score",
    defaultSortOrder: "desc",
  });

  const { values, setValues, clearValues } = useForm(GROUP_FORM_INITIAL_STATE);
  const [errorText, setErrorText] = useState("");

  const { apiRequest: createGroupApiRequest } = useApiRequest(
    createGroupApi,
    () => {
      setOpenDialog(false);
      fetchGroupListWithoutNameFilter();
      clearValues();
      setErrorText("");
    },
    (e) => {
      setErrorText(decodeApiError(e));
    }
  );

  const { apiRequest: updateGroupApiRequest } = useApiRequest(
    updateGroupApi,
    () => {
      setOpenDialog(false);
      fetchGroupListWithoutNameFilter();
      clearValues();
      setErrorText("");
    },
    (e) => {
      setErrorText(decodeApiError(e));
    }
  );

  const { apiRequest: gradeProposalApiRequst } = useApiRequest(
    gradeProposalApi,
    () => {
      setOpenGradingDialog(false);
      fetchGroupListWithoutNameFilter();
      alert(
        "Proposal is currently being graded. All scores will be updated once the process has finished"
      );
    },
    (e) => {
      alert(decodeApiError(e));
    }
  );

  const fetchGroupListWithoutNameFilter = useCallback(() => {
    params.id &&
      fetch({
        page: page + 1,
        limit: rowsPerPage,
        sortBy,
        sortOrder,
        competitionId: params.id,
      });
  }, [fetch, page, params.id, rowsPerPage, sortBy, sortOrder]);

  // initial user list fetch
  useEffect(() => {
    fetchGroupListWithoutNameFilter();
    if (params.id) {
      fetchCompetition(params.id);
      checkCompetitionHasBeenGradedApiRequest(params.id);
    }
  }, [
    checkCompetitionHasBeenGradedApiRequest,
    fetchCompetition,
    fetchGroupListWithoutNameFilter,
    params.id,
  ]);

  const handleSearchGroups = () => {
    params.id &&
      fetch({
        name: searchedName,
        page: page + 1,
        limit: rowsPerPage,
        competitionId: params.id,
      });
  };

  const handleOpenSortMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSortMenu = () => {
    setAnchorEl(null);
  };

  const handleCreateGroup = () => {
    setOpenDialog(true);
  };

  const handleExport = async () => {
    params.id && (await exportGroupsApi(params.id));
  };

  const handleSubmitGroupForm = () => {
    if (params.id) {
      if (values.id) {
        updateGroupApiRequest({
          ...values,
          members: Object.values(values.members),
          competitionId: params.id,
        });
      } else {
        createGroupApiRequest({
          ...values,
          members: Object.values(values.members),
          competitionId: params.id,
        });
      }
    }
  };

  const handleGradeProposals = () => {
    setOpenGradingDialog(true);
  };

  const handleSubmitGradeProposals = () => {
    params.id && gradeProposalApiRequst({ competition_id: params.id });
  };

  const isFormValid = useMemo(() => {
    if (!values.name) return false;

    const memberHasEmptyValues = Object.values(values.members).some(
      (member) => !member.name || !member.workUnit
    );
    if (memberHasEmptyValues) return false;

    return true;
  }, [values.members, values.name]);

  const allProposalHasBeenSubmitted = !res?.groups.some(
    (group) => !group.proposal
  );
  const allProposalHasBeenGraded = res?.groups.every(
    (group) => group.proposal?.status === ProposalSubmitStatus.GRADED
  );
  const isGradingDisabled = useMemo(() => {
    return (
      !allProposalHasBeenSubmitted || allProposalHasBeenGraded || hasBeenGraded
    );
  }, [allProposalHasBeenGraded, allProposalHasBeenSubmitted, hasBeenGraded]);

  const renderGroupListTable = useCallback(() => {
    if (loading) {
      return <LinearProgress />;
    }

    const groups = res?.groups ?? [];
    const totalCount = res?.pagination?.totalCount ?? 0;

    return (
      <GroupListTable
        error={error}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        hasBeenGraded={!!hasBeenGraded}
        groups={groups}
        refetchTableData={fetchGroupListWithoutNameFilter}
        setEditGroupValues={setValues}
        setOpenGroupForm={setOpenDialog}
        totalCount={totalCount}
      />
    );
  }, [
    loading,
    res?.groups,
    res?.pagination?.totalCount,
    error,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
    hasBeenGraded,
    fetchGroupListWithoutNameFilter,
    setValues,
  ]);

  return (
    <>
      <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Typography variant="h5" mb={2}>
          Group List - Competition {competitionRes?.competition.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <TextField
              label="Group Name"
              placeholder="Search group name"
              value={searchedName}
              onChange={(event) => {
                setSeachedName(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  params.id &&
                    fetch({
                      name: searchedName,
                      page: page + 1,
                      limit: rowsPerPage,
                      competitionId: params.id,
                    });
                }
              }}
              size="small"
              sx={{ minWidth: 300 }}
            />
            <Button variant="contained" onClick={handleSearchGroups}>
              Search
            </Button>

            <Button variant="outlined" onClick={handleOpenSortMenu}>
              Sort By{" "}
              {
                SORT_MENU.find(
                  (menu) =>
                    menu.sortBy === sortBy && menu.sortOrder === sortOrder
                )?.label
              }
              <ChevronRight fontSize="small" />
            </Button>
            <Menu
              open={openSortMenu}
              anchorEl={anchorEl}
              onClose={handleCloseSortMenu}
            >
              {SORT_MENU.map((menu) => (
                <MenuItem
                  onClick={() => {
                    handleSortationOnClick(menu.sortBy, menu.sortOrder);
                    handleCloseSortMenu();
                  }}
                >
                  {menu.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box display="flex" gap={2} alignItems="center">
            <Tooltip
              title={
                allProposalHasBeenSubmitted
                  ? allProposalHasBeenGraded
                    ? "All proposal has been graded"
                    : ""
                  : "Available after all proposal has been submitted"
              }
            >
              <div>
                <Button
                  variant="contained"
                  disabled={isGradingDisabled}
                  onClick={handleGradeProposals}
                >
                  Grade Proposals
                </Button>
              </div>
            </Tooltip>

            <Button variant="outlined" onClick={handleExport}>
              Export
            </Button>

            <Button variant="contained" onClick={handleCreateGroup}>
              Create
            </Button>
          </Box>
        </Box>
        {renderGroupListTable()}
      </Box>
      <Dialog
        open={openDialog}
        setOpen={setOpenDialog}
        onClose={clearValues}
        title={values.id ? "Update Group" : "Create New Group"}
        onSubmit={handleSubmitGroupForm}
        submitText={values.id ? "Save Update" : "Create"}
        submitDisabled={!isFormValid}
      >
        <GroupForm
          values={values}
          setValues={setValues}
          errorText={errorText}
        />
      </Dialog>
      <Dialog
        open={openGradingDialog}
        setOpen={setOpenGradingDialog}
        title="Grade All Submitted Proposals"
        onSubmit={handleSubmitGradeProposals}
        submitText={"Grade All"}
      >
        Only submitted proposals will be graded. This process can be repeated
        multiple times and results might vary depending on the number of
        submitted proposals. Are you sure to proceed this process?
      </Dialog>
    </>
  );
};

export default withSidebarLayout(GroupPage, { pageTitle: "Groups" });
