import {
  CreateGroupRequest,
  GetGroupListApiRequest,
  UpdateGroupRequest,
} from "../interfaces/group/req";
import {
  GetGroupApiResponse,
  GetGroupListApiResponse,
} from "../interfaces/group/res";
import { apiRequest } from "../utils/apiConfig";

export const getGroupListApi = async (
  params: GetGroupListApiRequest
): Promise<GetGroupListApiResponse> => {
  const { data } = await apiRequest.get("/groups", { params });

  return data;
};

export const getGroupApi = async (id: string): Promise<GetGroupApiResponse> => {
  const { data } = await apiRequest.get(`/groups/${encodeURIComponent(id)}`);

  return data;
};

export const exportGroupsApi = async (competitionId: string): Promise<void> => {
  const response = await apiRequest.post(
    `/groups/export`,
    { competitionId },
    { responseType: "blob" }
  );

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(new Blob([response.data]));

  // Create a link element
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `competition-data-${competitionId}.csv`); // Set the download attribute

  // Append the link to the body
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url); // Release the object URL
};

export const createGroupApi = async (
  params: CreateGroupRequest
): Promise<void> => {
  const { data } = await apiRequest.post("/groups", params);

  return data;
};

export const updateGroupApi = async (
  params: UpdateGroupRequest
): Promise<void> => {
  const { id, ...otherParams } = params;

  const { data } = await apiRequest.put(
    `/groups/${encodeURIComponent(id)}`,
    otherParams
  );

  return data;
};

export const deleteGroupApi = async (id: string): Promise<void> => {
  const { data } = await apiRequest.delete(`/groups/${encodeURIComponent(id)}`);

  return data;
};
