const normalizeString = (
  text: string,
  separator: string = " ",
  joiner: string = " "
): string => {
  return text
    .toLowerCase()
    .split(separator)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(joiner);
};

export default normalizeString;
