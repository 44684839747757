import {
  Dashboard,
  Groups,
  ManageAccounts,
  QuestionMark,
} from "@mui/icons-material";
import { Box, Button, Paper, Typography, alpha } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/AuthProvider";
import { useMemo } from "react";
import { UserRoles } from "../../constants/userRoles";
import { LocalStorageKeyEnum } from "../../constants/localStorage";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedInUser } = useAuth();
  const sidebarMenus = useMemo(() => {
    const menus = [
      {
        icon: <Dashboard />,
        text: "Overview",
        url: "/overview",
      },
      {
        icon: <Groups />,
        text: "Competitions",
        url: "/competitions",
      },
    ];

    if (loggedInUser?.role === UserRoles.SUPER_ADMIN) {
      menus.push({
        icon: <ManageAccounts />,
        text: "Account Management",
        url: "/accounts",
      });
    }

    menus.push({
      icon: <QuestionMark />,
      text: "About",
      url: "/about",
    });

    return menus;
  }, [loggedInUser?.role]);

  const handleMenuOnClick = (url: string) => () => {
    navigate(url);
  };

  const handleLogout = () => {
    localStorage.removeItem(LocalStorageKeyEnum.JWT);
    navigate("/login");
  };

  return (
    <Paper
      sx={{
        minWidth: 240,
        height: "100vh",
        maxHeight: "100vh",
        py: 2,
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
      }}
    >
      <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
        BI Digital
        <br />
        AI Quick Review
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {sidebarMenus.map((menu) => {
            const isSelected = location.pathname.includes(menu.url);
            return (
              <div onClick={handleMenuOnClick(menu.url)} key={menu.url}>
                <Box
                  key={menu.url}
                  sx={{
                    p: 1,
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                >
                  <Box
                    sx={(theme) => ({
                      p: 1,
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      backgroundColor: isSelected
                        ? alpha(theme.palette.primary.light, 0.15)
                        : "unset",
                      borderRadius: 2,
                      color: isSelected
                        ? theme.palette.primary.dark
                        : theme.palette.text.secondary,
                    })}
                  >
                    {menu.icon}
                    <Typography>{menu.text}</Typography>
                  </Box>
                </Box>
              </div>
            );
          })}
        </Box>
        <Box display="flex" flexDirection="column" gap={2} alignItems="center">
          <Button
            variant="contained"
            color="error"
            sx={{ alignSelf: "center", mb: 1 }}
            onClick={handleLogout}
          >
            Logout
          </Button>
          <Typography color="gray">Supported By</Typography>
          <Box
            alignSelf="center"
            width={200}
            display="flex"
            alignItems="center"
            gap={2}
            justifyContent="center"
          >
            <img src="/enclave.png" alt="enclave" width="40%" />
            <img src="/linxbrain.png" alt="linxbrain" width="40%" />
          </Box>
          <Typography color="gray" variant="caption">
            Copyright © Enclave 2024
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default Sidebar;
