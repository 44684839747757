import { useState } from "react";

type UseSortation = {
  sortBy: string;
  sortOrder: "asc" | "desc";
  handleSortationOnClick: (sortBy: string, sortOrder: "asc" | "desc") => void;
};

interface UseSortationProps {
  defaultSortBy?: string;
  defaultSortOrder?: "asc" | "desc";
}

export const useSortation = ({
  defaultSortBy = "name",
  defaultSortOrder = "asc",
}: UseSortationProps): UseSortation => {
  const [sortBy, setSortBy] = useState<string>(defaultSortBy);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">(defaultSortOrder);

  const handleSortationOnClick = (
    sortBy: string,
    sortOrder: "asc" | "desc"
  ): void => {
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  return { sortBy, sortOrder, handleSortationOnClick };
};
