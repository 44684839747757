import {
  GetUserListApiRequest,
  LoginApiReq,
  RegisterApiReq,
  ResetPasswordApiReq,
} from "../interfaces/user/req";
import {
  GetUserApiResponse,
  GetUserListApiResponse,
  LoginApiRes,
} from "../interfaces/user/res";
import { apiRequest } from "../utils/apiConfig";

export const loginApi = async (params: LoginApiReq): Promise<LoginApiRes> => {
  const { data } = await apiRequest.post("/users/login", params);

  return data;
};

export const registerApi = async (params: RegisterApiReq): Promise<void> => {
  const { data } = await apiRequest.post("/users", params);

  return data;
};

export const getUserListApi = async (
  params: GetUserListApiRequest
): Promise<GetUserListApiResponse> => {
  const { data } = await apiRequest.get("/users", { params });

  return data;
};

export const getSelfApi = async (): Promise<GetUserApiResponse> => {
  const { data } = await apiRequest.get("/users/self");

  return data;
};

export const deleteUserApi = async (id: string): Promise<void> => {
  const { data } = await apiRequest.delete(`/users/${encodeURIComponent(id)}`);

  return data;
};

export const resetPasswordApi = async (
  params: ResetPasswordApiReq
): Promise<void> => {
  const { data } = await apiRequest.post(
    `/users/${encodeURIComponent(params.id)}/reset-password`,
    { newPassword: params.password }
  );

  return data;
};
