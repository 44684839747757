import {
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { LocalStorageKeyEnum } from "../constants/localStorage";
import { User } from "../interfaces/user/fe";
import { getSelfApi } from "../api/users";

interface AuthState {
  loggedInUser?: User;
}

const initialState: AuthState = {
  loggedInUser: undefined,
};

type ActionType = "SAVE_USER";
type Action = {
  type: ActionType;
  payload?: any;
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case "SAVE_USER":
      return { ...state, loggedInUser: action.payload };
    default:
      return state;
  }
};

export const AuthContext = createContext<{
  state: AuthState;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

type Props = {
  children?: React.ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
  const isLoginRoute = window.location?.pathname.includes("/login");
  const isRootRoute = window.location?.pathname === "/";
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const jwt = localStorage.getItem(LocalStorageKeyEnum.JWT);
    if (jwt) {
      if (isLoginRoute) {
        window.location.replace("/");
      } else {
        getSelfApi().then((res) => {
          dispatch({ type: "SAVE_USER", payload: res.user });
          setIsLoggedIn(true);
        });
      }
    } else {
      if (!isLoginRoute) {
        window.location.replace("/login");
      }
    }
  }, [isLoginRoute, isRootRoute]);

  if (!isLoggedIn && !isLoginRoute) return <></>;

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const { state, dispatch } = useContext(AuthContext);

  const saveLoggedInUser = (user: User) => {
    dispatch({ type: "SAVE_USER", payload: user });
  };

  return { ...state, saveLoggedInUser };
};
