/* eslint-disable no-unused-vars */
import { Dispatch, SetStateAction, useState } from "react";

const DEFAULT_PAGE = 0;
const DEFAULT_ROWS_PER_PAGE = 10;
export type RowsPerPage = { value: number; label: number };
export const DEFAULT_ROWS_PER_PAGE_OPTIONS: number[] = [10, 25, 50];

export interface UsePagination {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
}

export type UseTable = UsePagination;

export const useTable = (params?: {
  page?: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
}): UseTable => {
  const [page, setPage] = useState<number>(params?.page ?? DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    params?.rowsPerPage ?? DEFAULT_ROWS_PER_PAGE
  );

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ): void => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return {
    page,
    setPage,
    rowsPerPage,
    rowsPerPageOptions:
      params?.rowsPerPageOptions ?? DEFAULT_ROWS_PER_PAGE_OPTIONS,
    handleChangePage,
    handleChangeRowsPerPage,
  };
};
