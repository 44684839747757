import { useCallback } from "react";
import { useLoadingOverlay } from "../providers/LoadingOverlayProvider";

export interface UseApiRequest<P> {
  apiRequest: (params: P) => Promise<void>;
}

export const useApiRequest = <P>(
  request: (params: P) => Promise<void>,
  onSuccess?: () => void,
  onError?: (e: any) => void
): UseApiRequest<P> => {
  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlay();

  const apiRequest = useCallback(
    async (params: P) => {
      showLoadingOverlay();
      try {
        await request(params);
        onSuccess?.();
      } catch (error) {
        onError?.(error);
        hideLoadingOverlay();
      } finally {
        hideLoadingOverlay();
      }
    },
    [hideLoadingOverlay, onError, onSuccess, request, showLoadingOverlay]
  );

  return {
    apiRequest,
  };
};
