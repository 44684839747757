import { MoreHoriz } from "@mui/icons-material";
import { TableRow, TableCell, IconButton, Menu, MenuItem } from "@mui/material";
import { UseTable } from "../../../hooks/useTable";
import { User } from "../../../interfaces/user/fe";
import Table from "../../commons/Table";
import normalizeString from "../../../utils/normalizeString";
import { useState } from "react";
import { deleteUserApi, resetPasswordApi } from "../../../api/users";
import Dialog from "../../commons/Dialog";

const USER_LIST_TABLE_HEADERS = ["No", "Name", "Username", "Role", ""];

type UserListTableProps = Omit<UseTable, "setPage"> & {
  users: User[];
  totalCount: number;
  error: boolean;
  refetchTableData: () => void;
};

const UserListTable = (props: UserListTableProps) => {
  const { users, totalCount, refetchTableData, ...tableProps } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState<boolean>(false);

  const handleOpenMenu =
    (userId: string) => (event: React.MouseEvent<HTMLElement>) => {
      setSelectedUserId(userId);
      setAnchorEl(event.currentTarget);
    };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleResetPassoword = (userId: string) => () => {
    resetPasswordApi({ id: userId, password: "" })
      .then(() => {
        alert("Password updated successfully");
        refetchTableData();
      })
      .finally(() => {
        handleCloseMenu();
      });
  };

  const handleDelete = (userId: string) => () => {
    setSelectedUserId(userId);
    setOpenDeleteConfirmationDialog(true);
  };

  const handleConfirmDelete = () => {
    selectedUserId &&
      deleteUserApi(selectedUserId)
        .then(() => {
          alert("User deleted successfully");
          refetchTableData();
        })
        .finally(() => {
          handleCloseMenu();
        });
  };

  return (
    <>
      <Table
        {...tableProps}
        headers={USER_LIST_TABLE_HEADERS}
        totalCount={totalCount}
      >
        {users.map((user, index) => (
          <TableRow key={user._id}>
            <TableCell>
              {tableProps.page * tableProps.rowsPerPage + (index + 1)}
            </TableCell>
            <TableCell>{user.name}</TableCell>
            <TableCell>{user.username}</TableCell>
            <TableCell>{normalizeString(user.role, "_")}</TableCell>
            <TableCell align="right">
              <IconButton onClick={handleOpenMenu(user._id.toString())}>
                <MoreHoriz />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      {selectedUserId && (
        <Menu open={openMenu} anchorEl={anchorEl} onClose={handleCloseMenu}>
          <MenuItem onClick={handleResetPassoword(selectedUserId)}>
            Reset Password
          </MenuItem>
          <MenuItem onClick={handleDelete(selectedUserId)}>Delete</MenuItem>
        </Menu>
      )}
      <Dialog
        title={"Delete User"}
        onSubmit={handleConfirmDelete}
        open={openDeleteConfirmationDialog}
        submitText={"Delete"}
        setOpen={setOpenDeleteConfirmationDialog}
      >
        Deleted data will be lost. Are you sure you want to delete this user?
      </Dialog>
    </>
  );
};

export default UserListTable;
