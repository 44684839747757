'use client';

import React, { Dispatch, createContext, useContext, useReducer } from 'react';

type LoadingOverlayState = {
  loading: boolean;
};

type ActionType = 'SHOW' | 'HIDE';

const initialState: LoadingOverlayState = {
  loading: false
};

const reducer = (state: LoadingOverlayState, action: ActionType): LoadingOverlayState => {
  switch (action) {
    case 'SHOW':
      return { ...state, loading: true };
    case 'HIDE':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export const LoadingOverlayContext = createContext<{
  state: LoadingOverlayState;
  dispatch: Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null
});

export const LoadingOverlayContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LoadingOverlayContext.Provider value={{ state, dispatch }}>
      {children}
    </LoadingOverlayContext.Provider>
  );
};

export const useLoadingOverlay = () => {
  const { state, dispatch } = useContext(LoadingOverlayContext);

  const showLoadingOverlay = () => {
    dispatch('SHOW');
  };

  const hideLoadingOverlay = () => {
    dispatch('HIDE');
  };

  return { ...state, showLoadingOverlay, hideLoadingOverlay };
};
