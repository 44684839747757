import { Box, Container, SxProps, Theme } from "@mui/material";

interface PageContainerProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}
const PageContainer: React.FC<PageContainerProps> = (props) => {
  const { children, sx } = props;

  return (
    <Box sx={sx}>
      <Container maxWidth="xl">{children}</Container>
    </Box>
  );
};

export default PageContainer;
