import { Box, Typography } from "@mui/material";
import withSidebarLayout from "../components/layout/withSidebarLayout";

const AboutPage = () => {
  return (
    <Box p={3}>
      <Box mb={2}>
        <Typography variant="h5">
          Overview Program Aku Bangga BI Bermakna (AB3) - BI Digital 2024
        </Typography>
      </Box>
      <Typography textAlign="justify">
        Program "AKU BANGGA BI BERMAKNA (AB3)" Bank Indonesia merupakan
        inisiatif komprehensif yang dirancang untuk membangun budaya inovasi,
        transformasi digital, dan kesejahteraan karyawan. Sejalan dengan visi
        strategis bank, AB3 dibangun di atas empat pilar utama: BI Inovasi, BI
        Digital, BI Prestasi, dan BI Spiritual. Program ini bertujuan
        meningkatkan keterlibatan karyawan, mendorong transformasi digital, dan
        pada akhirnya berkontribusi pada keberhasilan keseluruhan bank. Dengan
        mempromosikan budaya pembelajaran berkelanjutan, kolaborasi, dan
        inovasi, AB3 berupaya menempatkan Bank Indonesia sebagai lembaga
        keuangan terdepan.
      </Typography>
      <br />
      <Typography textAlign="justify">
        Untuk mengoperasionalkan AB3, Bank Indonesia telah menetapkan kerangka
        kerja terstruktur yang selaras dengan program strategis dan transformasi
        bank. Inisiatif ini melibatkan serangkaian kegiatan yang dirancang untuk
        membangun budaya inovasi, kolaborasi, dan kecakapan digital. Mulai dari
        program pengembangan kepemimpinan yang berfokus pada kepemimpinan
        spiritual strategis dan ecohappiness hingga inisiatif keterlibatan
        karyawan seperti "BTS YUK" (Best Teacher Best Student Yuk) dan "OBF"
        (One Big Family), AB3 memenuhi berbagai aspek pertumbuhan dan
        kesejahteraan karyawan.
      </Typography>
      <br />
      <Typography textAlign="justify">
        Untuk memastikan partisipasi yang luas, program ini dirancang untuk
        melibatkan karyawan pada berbagai level, mulai dari kontributor individu
        hingga seluruh departemen. Komponen utama adalah "Program Championship",
        yang mendorong tim untuk mengembangkan solusi inovatif untuk mengatasi
        tantangan spesifik. Melalui inisiatif seperti program "AKU KEREN", yang
        menekankan pembangunan kompetensi, dan fokus pada transformasi digital,
        AB3 bertujuan untuk membekali karyawan dengan keterampilan dan
        pengetahuan yang diperlukan untuk mendorong tujuan strategis bank.
      </Typography>
      <br />
      <Typography textAlign="justify">
        Program AB3 bertujuan untuk mendorong peningkatan signifikan dalam
        budaya organisasi dan kinerja Bank Indonesia. Dengan membangun budaya
        inovasi, kolaborasi, dan kecakapan digital, program ini berupaya
        meningkatkan keterlibatan karyawan, meningkatkan produktivitas, dan pada
        akhirnya berkontribusi pada tujuan strategis bank. Hasil utama termasuk
        pengembangan tenaga kerja yang lebih gesit dan responsif, implementasi
        inisiatif transformasi digital yang sukses, dan budaya organisasi yang
        lebih kuat yang berakar pada nilai-nilai inti bank. Untuk mengukur
        efektivitas program, Bank Indonesia menerapkan kerangka kerja evaluasi
        komprehensif yang menilai kepuasan karyawan, metrik kinerja, dan dampak
        program terhadap tujuan keseluruhan bank.
      </Typography>
      <br />
      <Box></Box>
      <Typography variant="h5">Fokus Implementasi BI Digital 2024</Typography>
      <br />
      <Typography variant="h6">
        - Digitalisasi serta Pemanfaatan AI dan ML
      </Typography>
      <br />
      <Typography textAlign="justify">
        Mendigitalisasi proses bisnis untuk mengefisiensikan sumber daya serta
        mengoptimalkan pemanfaatn input untuk berbagai output. Digitalisasi
        proses juga dapat diperkuat dengan memanfaatkan AI dan ML untuk berbagai
        hal seperti automasi proses, mendelegasikan beberapa bagian proses
        kerja, dan proses brainstorming.{" "}
      </Typography>
      <br />
      <Typography variant="h6">- Inovasi BPR dan 3S</Typography>
      <br />
      <Typography textAlign="justify">
        Memangkas dan mempercepat tahapan proses serta prosedur kerja yang masih
        melibatkan banyak aktivitas manual. Kegiatan inovasi diharapkan juga
        dapat menghasilkan proses bisnis yang berfokus pada simplifikasi,
        standardisasi, dan sistemisasi (3S) sehingga dapat mengurangi potensi
        risiko operasional, terutama yang bersifat human error.
      </Typography>
      <br />
      <Typography variant="h6">
        - Inovasi Layanan, Transparansi, dan Akuntabilitas
      </Typography>
      <br />
      <Typography textAlign="justify">
        Mendukung dan memperkuat tranasparansi dan komunikasi baik kepada publik
        maupun internal Bank Indonesia dalam rangka menjaga tata kelola dan
        akuntabilitas Bank Indonesia, baik yang bersifat laporan pelaksanaan dan
        wewenang, evaluasi kinerja, anggaran, maupun informasi lainnya.
      </Typography>
      <br />
      <Typography variant="h5">Keterlibatan Program Kerja Budaya</Typography>
      <br />
      <Typography textAlign="justify">
        Program budaya kerja melibatkan partisipasi aktif dari seluruh anggota
        organisasi, mulai dari individu hingga tingkat tim dan komunitas. Tim
        bertanggung jawab dalam merealisasikan dan mengkoordinasikan output
        perbaikan proses yang telah ditetapkan, serta mendorong seluruh anggota
        tim untuk memanfaatkan hasil tersebut secara optimal. Individu
        diharapkan secara aktif mengikuti kegiatan budaya kerja yang telah
        direncanakan, memanfaatkan hasil perbaikan proses, dan berbagi
        pengalamannya dengan rekan kerja lainnya. Satuan kerja berperan dalam
        menginternalisasi program, menyusun rencana aksi, dan memastikan bahwa
        semua kegiatan budaya kerja selaras dengan visi Bank Indonesia. Selain
        itu, komunitas dan lembaga afiliasi juga diajak untuk berpartisipasi
        dalam mendukung kegiatan budaya kerja, baik secara formal maupun
        informal, dengan melibatkan pihak eksternal.
      </Typography>
      <br />
      <Typography variant="h5">Timeline Pelaksanaan</Typography>
      <br />
      <Typography>Tahap Persiapan (April - Juli 2024) </Typography> <br />
      <Typography>
        April - Juli 2024: Tim diberikan pembekalan terkait digitalisasi,
        pemanfaatan AI dan ML. Juli 2024: Penyediaan panduan lengkap pelaksanaan
        tim digital, termasuk contoh tayangan dan referensi video. Tim mulai
        menyusun dan mengembangkan use case inovasi. Tahap Evaluasi dan
        Penyisihan (Agustus 2024)
      </Typography>
      <br />
      <Typography>
        {" "}
        Agustus 2024: Tim mengumpulkan output berupa tayangan use case dan video
        penjelasan. Dilakukan evaluasi terhadap seluruh use case yang masuk.
        Tim-tim terbaik akan dipilih untuk maju ke tahap semi finalis. Tahap
        Semi Finalis (Agustus 2024)
      </Typography>
      <br />
      <Typography>
        Agustus 2024: Dilaksanakan evaluasi semi finalis secara luring. Maksimal
        20 tim akan lolos ke tahap final. Pengumuman finalis akan dilakukan pada
        acara penutupan DIGIFESTAB3. Tahap Final (November 2024)
      </Typography>
      <br />
      <Typography>
        {" "}
        November 2024: Finalis diberikan panduan untuk membuat video pitching
        dan infografis. Finalis melakukan presentasi final. Penilaian mendalam
        dilakukan berdasarkan video pitching yang telah disiapkan.
      </Typography>
      <br />
      <Typography variant="h5">Kriteria Evaluasi Penyisihan</Typography>
      <br />
      <Typography textAlign="justify">
        Konsistensi: Seberapa baik use case menjawab permasalahan yang ada dan
        sesuai dengan tujuan yang ditetapkan. Kreativitas: Tingkat inovasi dan
        orisinalitas use case. Seberapa unik dan baru solusi yang ditawarkan.
        Keterlibatan: Tingkat keterlibatan tim dalam mengembangkan use case.
        Apakah melibatkan lintas satuan kerja. Apakah memanfaatkan sumber daya
        secara efektif. Dampak: Besarnya pengaruh use case terhadap kinerja
        organisasi. Apakah use case dapat meningkatkan efisiensi, efektivitas,
        atau kepatuhan
      </Typography>
    </Box>
  );
};

export default withSidebarLayout(AboutPage, {
  pageTitle: "About This Platform",
});
