import { MoreHoriz } from "@mui/icons-material";
import { TableRow, TableCell, IconButton, Menu, MenuItem } from "@mui/material";
import { UseTable } from "../../../hooks/useTable";
import Table from "../../commons/Table";
import { useState } from "react";
import {
  Competition,
  CompetitionFormInput,
} from "../../../interfaces/competition/fe";
import { useNavigate } from "react-router-dom";
import { deleteCompetitionApi } from "../../../api/competition";
import Dialog from "../../commons/Dialog";

const Competition_LIST_TABLE_HEADERS = ["No", "Name", "Total Group", ""];

type CompetitionListTableProps = Omit<UseTable, "setPage"> & {
  competitions: Competition[];
  totalCount: number;
  error: boolean;
  refetchTableData: () => void;
  setOpenGroupForm: React.Dispatch<React.SetStateAction<boolean>>;
  setEditCompetitionValues: React.Dispatch<
    React.SetStateAction<CompetitionFormInput>
  >;
};

const CompetitionListTable = (props: CompetitionListTableProps) => {
  const {
    competitions,
    totalCount,
    refetchTableData,
    setEditCompetitionValues,
    setOpenGroupForm,
    ...tableProps
  } = props;

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [selectedCompetition, setselectedCompetition] = useState<Competition>();

  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState<boolean>(false);

  const handleOpenMenu =
    (Competition: Competition) => (event: React.MouseEvent<HTMLElement>) => {
      setselectedCompetition(Competition);
      setAnchorEl(event.currentTarget);
    };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = (competition: Competition) => () => {
    setOpenGroupForm(true);
    setEditCompetitionValues({ id: competition._id, name: competition.name });
    handleCloseMenu();
  };

  const handleDelete = (competition: Competition) => () => {
    setselectedCompetition(competition);
    setOpenDeleteConfirmationDialog(true);
  };

  const handleConfirmDelete = () => {
    selectedCompetition &&
      deleteCompetitionApi(selectedCompetition?._id)
        .then(() => {
          alert("Competition deleted successfully");
          refetchTableData();
        })
        .finally(() => {
          handleCloseMenu();
        });
  };

  const handleView = (id: string) => () => {
    navigate(`/competitions/${encodeURIComponent(id)}`);
    handleCloseMenu();
  };

  return (
    <>
      <Table
        {...tableProps}
        headers={Competition_LIST_TABLE_HEADERS}
        totalCount={totalCount}
      >
        {competitions.map((competition, index) => (
          <TableRow key={competition._id}>
            <TableCell>
              {tableProps.page * tableProps.rowsPerPage + (index + 1)}
            </TableCell>
            <TableCell>{competition.name}</TableCell>
            <TableCell>{competition.groups.length}</TableCell>
            <TableCell align="right">
              <IconButton onClick={handleOpenMenu(competition)}>
                <MoreHoriz />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      {selectedCompetition && (
        <Menu open={openMenu} anchorEl={anchorEl} onClose={handleCloseMenu}>
          <MenuItem onClick={handleView(selectedCompetition._id)}>
            View
          </MenuItem>
          <MenuItem onClick={handleEdit(selectedCompetition)}>Edit</MenuItem>
          <MenuItem onClick={handleDelete(selectedCompetition)}>
            Delete
          </MenuItem>
        </Menu>
      )}
      <Dialog
        title={"Delete Competition"}
        onSubmit={handleConfirmDelete}
        open={openDeleteConfirmationDialog}
        submitText={"Delete"}
        setOpen={setOpenDeleteConfirmationDialog}
      >
        Deleted data will be lost. Are you sure you want to delete{" "}
        {selectedCompetition?.name}?
      </Dialog>
    </>
  );
};

export default CompetitionListTable;
