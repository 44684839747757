import { useNavigate } from "react-router-dom";
import withSidebarLayout from "../components/layout/withSidebarLayout";
import { useEffect } from "react";

const RootPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/overview");
  }, [navigate]);
  return <></>;
};

export default withSidebarLayout(RootPage, { pageTitle: "" });
