import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import withSidebarLayout from "../components/layout/withSidebarLayout";
import { useCallback, useEffect, useState } from "react";
import { useTable } from "../hooks/useTable";
import Dialog from "../components/commons/Dialog";
import { REGISTER_FORM_INITIAL_STATE } from "../constants/users";
import useForm from "../hooks/useForm";
import Select from "../components/commons/Select";
import { UserRoles } from "../constants/userRoles";
import { getUserListApi, registerApi } from "../api/users";
import { decodeApiError } from "../utils/decodeApiError";
import { useFetchState } from "../hooks/useFetchState";
import UserListTable from "../components/pages/AccountManagement/UserListTable";
import { useApiRequest } from "../hooks/useApiRequest";

const AccountManagementPage = () => {
  const { res, loading, error, fetch } = useFetchState(getUserListApi);
  const [searchedName, setSeachedName] = useState("");

  const { apiRequest } = useApiRequest(
    registerApi,
    () => {
      setOpenDialog(false);
      clearValues();
      fetchUserListWithoutNameFilter();
      setErrorText("");
    },
    (e) => {
      setErrorText(decodeApiError(e));
    }
  );

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { values, setValues, clearValues } = useForm(
    REGISTER_FORM_INITIAL_STATE
  );
  const [errorText, setErrorText] = useState("");

  const {
    page,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTable();

  const fetchUserListWithoutNameFilter = useCallback(() => {
    fetch({
      page: page + 1,
      limit: rowsPerPage,
    });
  }, [fetch, page, rowsPerPage]);

  // initial user list fetch
  useEffect(() => {
    fetchUserListWithoutNameFilter();
  }, [fetchUserListWithoutNameFilter]);

  const handleSearchUsers = () => {
    fetch({
      name: searchedName,
      page: page + 1,
      limit: rowsPerPage,
    });
  };

  const handleCreateUser = () => {
    setOpenDialog(true);
  };

  const handleSubmitCreateUser = () => {
    apiRequest(values);
  };

  const renderUserListTable = useCallback(() => {
    if (loading) {
      return <LinearProgress />;
    }

    const users = res?.users ?? [];
    const totalCount = res?.pagination.totalCount ?? 0;

    return (
      <UserListTable
        error={error}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        refetchTableData={fetchUserListWithoutNameFilter}
        users={users}
        totalCount={totalCount}
      />
    );
  }, [
    error,
    fetchUserListWithoutNameFilter,
    handleChangePage,
    handleChangeRowsPerPage,
    loading,
    page,
    res?.pagination.totalCount,
    res?.users,
    rowsPerPage,
    rowsPerPageOptions,
  ]);

  return (
    <>
      <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Typography variant="h5" mb={2}>
          Account List
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <TextField
              label="Name"
              placeholder="Search name"
              value={searchedName}
              onChange={(event) => {
                setSeachedName(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  fetch({
                    name: searchedName,
                    page: page + 1,
                    limit: rowsPerPage,
                  });
                }
              }}
              size="small"
              sx={{ minWidth: 300 }}
            />
            <Button variant="contained" onClick={handleSearchUsers}>
              Search
            </Button>
          </Box>
          <Button
            sx={{ mb: 2, alignSelf: "flex-end" }}
            variant="contained"
            onClick={handleCreateUser}
          >
            Create New Account
          </Button>
        </Box>
        {renderUserListTable()}
      </Box>
      <Dialog
        open={openDialog}
        setOpen={setOpenDialog}
        onClose={clearValues}
        title="Create New User"
        onSubmit={handleSubmitCreateUser}
        submitText={"Create"}
        submitDisabled={Object.values(values).some((value) => !value)}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, py: 1 }}>
          <TextField
            label="Name"
            value={values.name}
            onChange={(event) => {
              setValues((oldValues) => ({
                ...oldValues,
                name: event.target.value,
              }));
            }}
          />
          <TextField
            label="Username"
            value={values.username}
            onChange={(event) => {
              setValues((oldValues) => ({
                ...oldValues,
                username: event.target.value,
              }));
            }}
          />
          <TextField
            label="Password"
            type="password"
            value={values.password}
            onChange={(event) => {
              setValues((oldValues) => ({
                ...oldValues,
                password: event.target.value,
              }));
            }}
          />
          <Select
            label="Role"
            value={values.role}
            options={Object.entries(UserRoles)
              .filter(
                (role) => (role[1] as UserRoles) !== UserRoles.SUPER_ADMIN
              )
              .map(([key, value]) => ({
                label: key.split("_").join(" "),
                value: value,
              }))}
            onChange={(value) => {
              setValues((oldValues) => ({
                ...oldValues,
                role: value,
              }));
            }}
          />
        </Box>
        {errorText && (
          <Typography variant="caption" color="error">
            {errorText}
          </Typography>
        )}
      </Dialog>
    </>
  );
};

export default withSidebarLayout(AccountManagementPage, {
  pageTitle: "Account Management",
});
