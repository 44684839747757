import { MoreHoriz } from "@mui/icons-material";
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Divider,
} from "@mui/material";
import { UseTable } from "../../../hooks/useTable";
import Table from "../../commons/Table";
import { useState } from "react";
import { Group, GroupFormInput } from "../../../interfaces/group/fe";
import { generateProposalStatusChipColor } from "../../../utils/proposalStatusUtil";
import Dialog from "../../commons/Dialog";
import UploadProposalForm from "./UploadProposalForm";
import useForm from "../../../hooks/useForm";
import { UPLOAD_PROPOSAL_FORM_INITIAL_STATE } from "../../../constants/proposals";
import { useApiRequest } from "../../../hooks/useApiRequest";
import { createProposalApi } from "../../../api/proposals";
import { decodeApiError } from "../../../utils/decodeApiError";
import { ProposalSubmitStatus } from "../../../constants/proposalStatus";
import normalizeString from "../../../utils/normalizeString";
import { useNavigate } from "react-router-dom";
import { deleteGroupApi } from "../../../api/groups";
import { MemberFormInput } from "../../../interfaces/member/fe";

const GROUP_LIST_TABLE_HEADERS = [
  "No",
  "Name",
  "Members",
  "Proposal Status",
  "Score",
  "",
];

type GroupListTableProps = Omit<UseTable, "setPage"> & {
  groups: Group[];
  totalCount: number;
  error: boolean;
  hasBeenGraded: boolean;
  refetchTableData: () => void;
  setOpenGroupForm: React.Dispatch<React.SetStateAction<boolean>>;
  setEditGroupValues: React.Dispatch<React.SetStateAction<GroupFormInput>>;
};

const GroupListTable = (props: GroupListTableProps) => {
  const {
    groups,
    totalCount,
    hasBeenGraded,
    refetchTableData,
    setOpenGroupForm,
    setEditGroupValues,
    ...tableProps
  } = props;

  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    useState<boolean>(false);

  const { apiRequest } = useApiRequest(
    createProposalApi,
    () => {
      setOpenDialog(false);
      clearValues();
      refetchTableData();
      setErrorText("");
      alert("Proposal Submitted Successfully");
    },
    (e) => {
      setErrorText(decodeApiError(e));
    }
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [selectedGroup, setSelectedGroup] = useState<Group>();

  const { values, setValues, clearValues } = useForm(
    UPLOAD_PROPOSAL_FORM_INITIAL_STATE
  );
  const [errorText, setErrorText] = useState("");

  const handleOpenMenu =
    (group: Group) => (event: React.MouseEvent<HTMLElement>) => {
      setSelectedGroup(group);
      setAnchorEl(event.currentTarget);
    };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = (group: Group) => () => {
    setOpenGroupForm(true);
    setEditGroupValues({
      id: group._id,
      name: group.name,
      members: group.members.reduce(
        (acc, member, i) => {
          acc[i] = {
            id: member._id,
            name: member.name,
            workUnit: member.work_unit,
          };
          return acc;
        },
        {} as {
          [key: string]: MemberFormInput;
        }
      ),
    });
    handleCloseMenu();
  };

  const handleDelete = (group: Group) => () => {
    setSelectedGroup(group);
    setOpenDeleteConfirmationDialog(true);
  };

  const handleConfirmDelete = () => {
    selectedGroup &&
      deleteGroupApi(selectedGroup?._id)
        .then(() => {
          alert("Group deleted successfully");
          refetchTableData();
        })
        .finally(() => {
          handleCloseMenu();
          setOpenDeleteConfirmationDialog(false);
        });
  };

  const handleView = (id: string) => () => {
    navigate(`/groups/${encodeURIComponent(id)}`);
    handleCloseMenu();
  };

  const handleSubmitProposal = (group: Group) => () => {
    setValues((oldVal) => ({ ...oldVal, groupId: group._id }));
    setOpenDialog(true);
    handleCloseMenu();
  };

  const handleSubmitCreateProposal = () => {
    values.file &&
      apiRequest({
        group_id: values.groupId,
        file: values.file,
        ps_pt_code: values.psPt,
        pain_point: values.painPoint,
      });
  };

  return (
    <>
      <Table
        {...tableProps}
        headers={GROUP_LIST_TABLE_HEADERS}
        totalCount={totalCount}
      >
        {groups.map((group, index) => (
          <TableRow key={group._id}>
            <TableCell>
              {tableProps.page * tableProps.rowsPerPage + (index + 1)}
            </TableCell>
            <TableCell>{group.name}</TableCell>
            <TableCell>
              {group.members.map((member, i) => {
                if (i < group.members.length - 1) {
                  return member.name + ", ";
                }

                return member.name;
              })}
            </TableCell>
            <TableCell>
              <Chip
                label={normalizeString(
                  group.proposal?.status ?? ProposalSubmitStatus.NOT_SUBMITTED,
                  "_"
                )}
                color={generateProposalStatusChipColor(
                  group.proposal?.status ?? ProposalSubmitStatus.NOT_SUBMITTED
                )}
              />
            </TableCell>
            <TableCell>{group.proposal?.score?.total ?? "-"}</TableCell>
            <TableCell align="right">
              <IconButton onClick={handleOpenMenu(group)}>
                <MoreHoriz />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      {selectedGroup && (
        <Menu open={openMenu} anchorEl={anchorEl} onClose={handleCloseMenu}>
          <MenuItem onClick={handleView(selectedGroup._id)}>View</MenuItem>
          {!hasBeenGraded && (
            <MenuItem onClick={handleEdit(selectedGroup)}>Edit</MenuItem>
          )}
          {!selectedGroup?.proposal && !hasBeenGraded && (
            <>
              <Divider />
              <MenuItem onClick={handleSubmitProposal(selectedGroup)}>
                Submit Proposal
              </MenuItem>
            </>
          )}
          {!hasBeenGraded && (
            <MenuItem onClick={handleDelete(selectedGroup)}>Delete</MenuItem>
          )}
        </Menu>
      )}
      <Dialog
        open={openDialog}
        setOpen={setOpenDialog}
        onClose={clearValues}
        title={`${selectedGroup?.name} - Submit New Proposal`}
        onSubmit={handleSubmitCreateProposal}
        submitText={"Submit"}
        submitDisabled={Object.values(values).some((value) => !value)}
      >
        <UploadProposalForm
          values={values}
          setValues={setValues}
          errorText={errorText}
        />
      </Dialog>
      <Dialog
        title={"Delete Group"}
        onSubmit={handleConfirmDelete}
        open={openDeleteConfirmationDialog}
        submitText={"Delete"}
        setOpen={setOpenDeleteConfirmationDialog}
      >
        Deleted data will be lost. Are you sure you want to delete{" "}
        {selectedGroup?.name}?
      </Dialog>
    </>
  );
};

export default GroupListTable;
