import {
  Box,
  Button,
  LinearProgress,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import withSidebarLayout from "../components/layout/withSidebarLayout";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTable } from "../hooks/useTable";
import Dialog from "../components/commons/Dialog";
import useForm from "../hooks/useForm";
import { decodeApiError } from "../utils/decodeApiError";
import { useFetchState } from "../hooks/useFetchState";
import { useApiRequest } from "../hooks/useApiRequest";
import { ChevronRight } from "@mui/icons-material";
import { useSortation } from "../hooks/useSortation";
import {
  createCompetitionApi,
  getCompetitionListApi,
  updateCompetitionApi,
} from "../api/competition";
import CompetitionListTable from "../components/pages/Competitions/CompetitionListTable";
import CompetitionForm from "../components/pages/Competitions/CompetitionForm";
import { COMPETITION_FORM_INITIAL_STATE } from "../constants/competition";

const SORT_MENU: {
  label: string;
  sortBy: string;
  sortOrder: "asc" | "desc";
}[] = [
  {
    label: "Name (A-Z)",
    sortBy: "name",
    sortOrder: "asc",
  },
  {
    label: "Name (Z-A)",
    sortBy: "name",
    sortOrder: "desc",
  },
];

const CompetitionListPage = () => {
  const { res, loading, error, fetch } = useFetchState(getCompetitionListApi);

  const [searchedName, setSeachedName] = useState("");

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openSortMenu = Boolean(anchorEl);

  const {
    page,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTable();

  const { sortBy, sortOrder, handleSortationOnClick } = useSortation({
    defaultSortBy: "name",
    defaultSortOrder: "asc",
  });

  const { values, setValues, clearValues } = useForm(
    COMPETITION_FORM_INITIAL_STATE
  );
  const [errorText, setErrorText] = useState("");

  const { apiRequest: createCompetitionApiRequest } = useApiRequest(
    createCompetitionApi,
    () => {
      setOpenDialog(false);
      fetchCompetitionListWithoutNameFilter();
      clearValues();
      setErrorText("");
    },
    (e) => {
      setErrorText(decodeApiError(e));
    }
  );

  const { apiRequest: updateCompetitionApiRequest } = useApiRequest(
    updateCompetitionApi,
    () => {
      setOpenDialog(false);
      fetchCompetitionListWithoutNameFilter();
      clearValues();
      setErrorText("");
    },
    (e) => {
      setErrorText(decodeApiError(e));
    }
  );

  const fetchCompetitionListWithoutNameFilter = useCallback(() => {
    fetch({
      page: page + 1,
      limit: rowsPerPage,
      sortBy,
      sortOrder,
    });
  }, [fetch, page, rowsPerPage, sortBy, sortOrder]);

  // initial user list fetch
  useEffect(() => {
    fetchCompetitionListWithoutNameFilter();
  }, [fetchCompetitionListWithoutNameFilter]);

  const handleSearch = () => {
    fetch({
      name: searchedName,
      page: page + 1,
      limit: rowsPerPage,
    });
  };

  const handleOpenSortMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSortMenu = () => {
    setAnchorEl(null);
  };

  const handleCreate = () => {
    setOpenDialog(true);
  };

  const handleSubmitForm = () => {
    if (values.id) {
      updateCompetitionApiRequest({ ...values });
    } else {
      createCompetitionApiRequest({ ...values });
    }
  };

  const isFormValid = useMemo(() => {
    if (!values.name) return false;

    return true;
  }, [values.name]);

  const renderGroupListTable = useCallback(() => {
    if (loading) {
      return <LinearProgress />;
    }

    const competitions = res?.competitions ?? [];
    const totalCount = res?.pagination?.totalCount ?? 0;

    return (
      <CompetitionListTable
        error={error}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        competitions={competitions}
        refetchTableData={fetchCompetitionListWithoutNameFilter}
        setEditCompetitionValues={setValues}
        setOpenGroupForm={setOpenDialog}
        totalCount={totalCount}
      />
    );
  }, [
    loading,
    res?.competitions,
    res?.pagination?.totalCount,
    error,
    page,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
    fetchCompetitionListWithoutNameFilter,
    setValues,
  ]);

  return (
    <>
      <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Typography variant="h5" mb={2}>
          Competition List
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <TextField
              label="Name"
              placeholder="Search name"
              value={searchedName}
              onChange={(event) => {
                setSeachedName(event.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  fetch({
                    name: searchedName,
                    page: page + 1,
                    limit: rowsPerPage,
                  });
                }
              }}
              size="small"
              sx={{ minWidth: 300 }}
            />
            <Button variant="contained" onClick={handleSearch}>
              Search
            </Button>

            <Button variant="outlined" onClick={handleOpenSortMenu}>
              Sort By{" "}
              {
                SORT_MENU.find(
                  (menu) =>
                    menu.sortBy === sortBy && menu.sortOrder === sortOrder
                )?.label
              }
              <ChevronRight fontSize="small" />
            </Button>
            <Menu
              open={openSortMenu}
              anchorEl={anchorEl}
              onClose={handleCloseSortMenu}
            >
              {SORT_MENU.map((menu) => (
                <MenuItem
                  onClick={() => {
                    handleSortationOnClick(menu.sortBy, menu.sortOrder);
                    handleCloseSortMenu();
                  }}
                >
                  {menu.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Button
            sx={{ mb: 2, alignSelf: "flex-end" }}
            variant="contained"
            onClick={handleCreate}
          >
            Create
          </Button>
        </Box>
        {renderGroupListTable()}
      </Box>
      <Dialog
        open={openDialog}
        setOpen={setOpenDialog}
        onClose={clearValues}
        title={values.id ? "Update Competition" : "Create New Competition"}
        onSubmit={handleSubmitForm}
        submitText={values.id ? "Save Update" : "Create"}
        submitDisabled={!isFormValid}
      >
        <CompetitionForm
          values={values}
          setValues={setValues}
          errorText={errorText}
        />
      </Dialog>
    </>
  );
};

export default withSidebarLayout(CompetitionListPage, {
  pageTitle: "Competitions",
});
