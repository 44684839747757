import {
  CreateCompetitionRequest,
  GetCompetitionListApiRequest,
  UpdateCompetitionRequest,
} from "../interfaces/competition/req";
import {
  GetCompetitionApiResponse,
  GetCompetitionListApiResponse,
} from "../interfaces/competition/res";
import { apiRequest } from "../utils/apiConfig";

export const getCompetitionListApi = async (
  params: GetCompetitionListApiRequest
): Promise<GetCompetitionListApiResponse> => {
  const { data } = await apiRequest.get("/competitions", { params });

  return data;
};

export const getCompetitionApi = async (
  id: string
): Promise<GetCompetitionApiResponse> => {
  const { data } = await apiRequest.get(
    `/competitions/${encodeURIComponent(id)}`
  );

  return data;
};

export const createCompetitionApi = async (
  params: CreateCompetitionRequest
): Promise<void> => {
  const { data } = await apiRequest.post("/competitions", params);

  return data;
};

export const updateCompetitionApi = async (
  params: UpdateCompetitionRequest
): Promise<void> => {
  const { id, ...otherParams } = params;
  const { data } = await apiRequest.put(
    `/competitions/${encodeURIComponent(id)}`,
    otherParams
  );

  return data;
};

export const deleteCompetitionApi = async (id: string): Promise<void> => {
  const { data } = await apiRequest.delete(
    `/competitions/${encodeURIComponent(id)}`
  );

  return data;
};

export const checkCompetitionHasBeenGradedApi = async (
  id: string
): Promise<boolean> => {
  const { data } = await apiRequest.get(
    `/competitions/${encodeURIComponent(id)}/check`
  );

  return data;
};
