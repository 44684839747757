import { Backdrop, CircularProgress } from "@mui/material";
import { useLoadingOverlay } from "../../providers/LoadingOverlayProvider";

const LoadingOverlay = () => {
  const { loading } = useLoadingOverlay();

  if (!loading) return <></>;

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingOverlay;
