import {
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
} from "@mui/material";

type DialogProps = Omit<MuiDialogProps, "onClose"> & {
  title: string;
  children: React.ReactNode;
  onSubmit: () => void;
  onClose?: () => void;
  submitText: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  submitDisabled?: boolean;
};

const Dialog = (props: DialogProps) => {
  const {
    children,
    title,
    onSubmit,
    submitText,
    setOpen,
    onClose,
    submitDisabled,
    ...muiDialogProps
  } = props;

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  return (
    <MuiDialog onClose={handleClose} {...muiDialogProps} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={onSubmit}
          disabled={submitDisabled}
          variant="contained"
        >
          {submitText}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
