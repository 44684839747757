import { createBrowserRouter } from "react-router-dom";
import RootPage from "../pages/RootPage";
import LoginPage from "../pages/LoginPage";
import AccountManagementPage from "../pages/AccountManagementPage";
import GroupListPage from "../pages/group/GroupListPage";
import GroupDetailsPage from "../pages/group/GroupDetailsPage";
import AboutPage from "../pages/AboutPage";
import OverviewPage from "../pages/OverviewPage";
import CompetitionListPage from "../pages/CompetitionListPage";
import OverviewPrintPage from "../pages/OverviewPrintPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/overview",
    element: <OverviewPage />,
  },
  {
    path: "/overview/print",
    element: <OverviewPrintPage />,
  },
  {
    path: "/accounts",
    element: <AccountManagementPage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/competitions",
    element: <CompetitionListPage />,
  },
  {
    path: "/competitions/:id",
    element: <GroupListPage />,
  },
  {
    path: "/groups/:id",
    element: <GroupDetailsPage />,
  },
]);
