import { Box, Button, TextField, Typography } from "@mui/material";
import PageContainer from "../components/commons/PageContainer";
import useForm from "../hooks/useForm";
import { loginApi } from "../api/users";
import { useState } from "react";
import { decodeApiError } from "../utils/decodeApiError";
import { LocalStorageKeyEnum } from "../constants/localStorage";
import { useNavigate } from "react-router-dom";
import { LOGIN_FORM_INITIAL_STATE } from "../constants/users";
import { useAuth } from "../providers/AuthProvider";

const LoginPage = () => {
  const navigate = useNavigate();
  const { values, setValues } = useForm(LOGIN_FORM_INITIAL_STATE);
  const [errorText, setErrorText] = useState("");
  const { saveLoggedInUser } = useAuth();

  const handleLogin = () => {
    loginApi(values)
      .then((res) => {
        localStorage.setItem(LocalStorageKeyEnum.JWT, res.token);
        saveLoggedInUser(res.user);
        navigate("/");
      })
      .catch((e) => setErrorText(decodeApiError(e)));
  };

  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Typography mb={3} variant="h4" fontWeight={500}>
          Digital Competition
        </Typography>
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            gap: 2,
            minWidth: 400,
            p: 3,
            borderRadius: 2,
            boxShadow: "3px 2px 4px 1px rgba(0,0,0,0.42);",
          })}
        >
          <Typography mb={2} variant="h5">
            Login
          </Typography>
          <TextField
            label="Username"
            type="email"
            value={values.username}
            onChange={(event) => {
              setValues((oldValues) => ({
                ...oldValues,
                username: event.target.value,
              }));
            }}
          />
          <TextField
            label="Password"
            type="password"
            value={values.password}
            onChange={(event) => {
              setValues((oldValues) => ({
                ...oldValues,
                password: event.target.value,
              }));
            }}
          />
          {errorText && (
            <Typography variant="caption" color="error">
              * {errorText}
            </Typography>
          )}
          <Button
            variant="contained"
            onClick={handleLogin}
            disabled={!values.username && !values.password}
          >
            Login
          </Button>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          mt={3}
          gap={1}
        >
          <Typography>Supported By</Typography>
          <Box
            alignSelf="center"
            width={300}
            display="flex"
            alignItems="center"
            gap={2}
            justifyContent="center"
          >
            <img src="/enclave.png" alt="enclave" width="40%" />
            <img src="/linxbrain.png" alt="linxbrain" width="40%" />
          </Box>
          <Typography variant="caption">Copyright © Enclave 2024</Typography>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default LoginPage;
