import { useCallback, useState } from "react";

export interface UseFetchState<T, P> {
  res?: T;
  loading: boolean;
  error: boolean;
  fetch: (params: P) => Promise<T | undefined>;
}

export const useFetchState = <T, P>(
  fetchApi: (params: P) => Promise<T>
): UseFetchState<T, P> => {
  const [res, setRes] = useState<T>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const fetch = useCallback(
    async (params: P) => {
      setLoading(true);
      setError(false);
      try {
        const res = await fetchApi(params);
        setRes(res);
        return res;
      } catch (error) {
        setRes(undefined);
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [fetchApi]
  );

  return {
    res,
    loading,
    error,
    fetch,
  };
};
