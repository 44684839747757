import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export interface DefaultAppBarProps {
  pageTitle: string;
  showBackButton?: boolean;
}

const DefaultAppBar = (props: DefaultAppBarProps) => {
  const { pageTitle, showBackButton = false } = props;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          {showBackButton && (
            <IconButton sx={{ color: "white" }} onClick={handleBack}>
              <ChevronLeft />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {pageTitle}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default DefaultAppBar;
