import axios from "axios";
import { LocalStorageKeyEnum } from "../constants/localStorage";

export const apiRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

apiRequest.interceptors.request.use(function (config) {
  const token = localStorage.getItem(LocalStorageKeyEnum.JWT);
  if (token) {
    config.headers = Object.assign(
      {
        Authorization: `Bearer ${token}`,
      },
      config.headers
    );
  }
  return config;
});

export const clearAppLocalStorage = () => {
  localStorage.removeItem(LocalStorageKeyEnum.JWT);
};

apiRequest.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    const status = error.response?.status;
    if (status === 500) {
      alert(error.response.data.message);
      // clearAppLocalStorage();
      // window.location.replace('/login');
    }

    if (status === 401) {
      alert("Session Expired. Please relogin to the platform.");
      clearAppLocalStorage();
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);
