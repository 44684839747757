import { LoginFormInput, RegisterFormInput } from "../interfaces/user/fe";

export const LOGIN_FORM_INITIAL_STATE: LoginFormInput = {
  username: "",
  password: "",
};

export const REGISTER_FORM_INITIAL_STATE: RegisterFormInput = {
  name: "",
  username: "",
  password: "",
  role: "",
};
