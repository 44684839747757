import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SelectChangeEvent,
} from "@mui/material";

type SelectProps = Omit<MuiSelectProps, "onChange"> & {
  label: string;
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
};

const Select = (props: SelectProps) => {
  const { label, options, onChange, ...muiSelectProps } = props;

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    onChange(e.target.value as string);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <MuiSelect label={label} {...muiSelectProps} onChange={handleChange}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{
              maxWidth: 800,
              "&.MuiMenuItem-root": {
                textWrap: "wrap",
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
