import { Box, Typography, TextField } from "@mui/material";
import { UseForm } from "../../../hooks/useForm";
import { CompetitionFormInput } from "../../../interfaces/competition/fe";

type CompetitionFormProps = Pick<
  UseForm<CompetitionFormInput>,
  "values" | "setValues"
> & {
  errorText: string;
};

const CompetitionForm = (props: CompetitionFormProps) => {
  const { values, setValues, errorText } = props;

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, py: 1 }}>
        <TextField
          label="Competition Name"
          value={values.name}
          onChange={(event) => {
            setValues((oldValues) => ({
              ...oldValues,
              name: event.target.value,
            }));
          }}
        />
      </Box>

      {errorText && (
        <Typography variant="caption" color="error">
          {errorText}
        </Typography>
      )}
    </>
  );
};

export default CompetitionForm;
