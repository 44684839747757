import {
  Box,
  Button,
  Chip,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import withSidebarLayout from "../../components/layout/withSidebarLayout";
import { useParams } from "react-router-dom";
import { getGroupApi } from "../../api/groups";
import { useEffect } from "react";
import { useFetchState } from "../../hooks/useFetchState";
import { AccountBox } from "@mui/icons-material";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import normalizeString from "../../utils/normalizeString";
import { generateProposalStatusChipColor } from "../../utils/proposalStatusUtil";
import { ProposalSubmitStatus } from "../../constants/proposalStatus";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const GroupDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { res, loading, error, fetch } = useFetchState(getGroupApi);

  useEffect(() => {
    if (id) {
      fetch(id);
    }
  }, [fetch, id]);

  const openNewTab = (url: string) => () => {
    window.open(url, "_blank");
  };

  const renderGroupDetails = () => {
    if (loading) {
      return <LinearProgress />;
    }

    const group = res?.group;

    if (error || !group) return <>Something went wrong, try again later.</>;

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Typography fontWeight="bold" variant="h5">
          {group.name}
        </Typography>
        <Typography fontWeight="medium">Team Members</Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          {group.members.map((member) => {
            return (
              <Box
                key={member._id}
                sx={{
                  p: 2,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  border: "1px lightgrey solid",
                  borderRadius: 2,
                  minWidth: 200,
                }}
              >
                <AccountBox fontSize="medium" />
                <Box>
                  <Typography fontWeight="bold" variant="body2">
                    {member.name}
                    {member.work_unit && <> - {member.work_unit}</>}
                  </Typography>
                  {member.is_pic && (
                    <Typography variant="body2">PIC</Typography>
                  )}
                  {member.non_organic && (
                    <Typography variant="body2">Non-Organik</Typography>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box display="flex" gap={2} alignItems="center">
          <Typography fontWeight="medium" variant="h5">
            Proposal
          </Typography>
          <Chip
            label={normalizeString(
              group.proposal?.status ?? ProposalSubmitStatus.NOT_SUBMITTED,
              "_"
            )}
            color={generateProposalStatusChipColor(
              group.proposal?.status ?? ProposalSubmitStatus.NOT_SUBMITTED
            )}
          />
        </Box>

        <Box display="flex" gap={2}>
          {group.proposal?.pdf_url && (
            <Button onClick={openNewTab(group.proposal?.pdf_url)}>
              View PDF
            </Button>
          )}
          {group.proposal?.video_url && (
            <Button onClick={openNewTab(group.proposal?.video_url)}>
              View Video
            </Button>
          )}
        </Box>
        <Divider />
        {group.proposal ? (
          <>
            <Box>
              <Typography variant="h6" fontWeight="bold">
                Total Score: {group.proposal.score?.total}
              </Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box sx={{ width: 600 }}>
                  <Radar
                    data={{
                      labels: [
                        "Consistency",
                        "Creativity",
                        "Impact",
                        "Involvement",
                      ],
                      datasets: [
                        {
                          label: "Score",
                          data: [
                            group.proposal.score?.consistency,
                            group.proposal.score?.creativity,
                            group.proposal.score?.impact,
                            group.proposal.score?.involvement,
                          ],
                          fill: true,
                          backgroundColor: "rgba(255, 99, 132, 0.2)",
                          borderColor: "rgb(255, 99, 132)",
                        },
                      ],
                    }}
                    options={{
                      scales: {
                        r: {
                          angleLines: {
                            display: false,
                          },
                          suggestedMin: 500,
                          suggestedMax: 1000,
                          ticks: {
                            stepSize: 100,
                          },
                        },
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      mb: 2,
                      border: "1px solid lightgrey",
                      borderRadius: 1,
                      p: 1.5,
                    }}
                  >
                    {group.proposal?.score &&
                      group.proposal?.score?.penalty > 0 && (
                        <Typography color="red">
                          {group.proposal.score.penalty}% Penalty
                        </Typography>
                      )}

                    <Typography variant="body1" fontWeight="bold" mt={1}>
                      Feedback Proposal
                    </Typography>
                    <Typography variant="body2" textAlign="justify">
                      {group.proposal.feedback}
                    </Typography>
                    <Typography variant="body1" fontWeight="bold" mt={1}>
                      Feedback Video
                    </Typography>
                    <Typography variant="body2" textAlign="justify">
                      {group.proposal.feedback_video}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <>Upload Proposal</>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        {renderGroupDetails()}
      </Box>
      {/* <Dialog
    open={openDialog}
    setOpen={setOpenDialog}
    onClose={clearValues}
    title="Create New Group"
    onSubmit={handleSubmitCreateGroup}
    submitText={"Create"}
    submitDisabled={!isFormValid}
  >
    <CreateGroupForm
      values={values}
      setValues={setValues}
      errorText={errorText}
    />
  </Dialog> */}
    </>
  );
};

export default withSidebarLayout(GroupDetailsPage, {
  pageTitle: "Group Details",
  showBackButton: true,
});
