import { ProposalSubmitStatus } from "../constants/proposalStatus";

export const generateProposalStatusChipColor = (
  status?: string
):
  | "error"
  | "default"
  | "info"
  | "primary"
  | "secondary"
  | "success"
  | "warning" => {
  switch (status) {
    case ProposalSubmitStatus.NOT_SUBMITTED:
      return "error";
    case ProposalSubmitStatus.SUBMITTED:
      return "info";
    case ProposalSubmitStatus.IN_PROGRESS:
      return "info";
    case ProposalSubmitStatus.GRADED:
      return "success";
    default:
      return "default";
  }
};
