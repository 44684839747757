import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useFetchState } from "../hooks/useFetchState";
import { getSummaryApi } from "../api/summary";
import { useEffect } from "react";
import { AccountCircle, Article, Groups2, Work } from "@mui/icons-material";

import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
} from "chart.js";
import normalizeString from "../utils/normalizeString";
import { generateProposalStatusChipColor } from "../utils/proposalStatusUtil";

ChartJS.register(
  BarElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  ArcElement
);

const OverviewPrintPage = () => {
  const { res, loading, error, fetch } = useFetchState(getSummaryApi);
  const theme = useTheme();

  useEffect(() => {
    fetch({});
  }, [fetch]);

  const getColorInRGBA = (
    colorName:
      | "error"
      | "default"
      | "info"
      | "primary"
      | "secondary"
      | "success"
      | "warning",
    opacity = 1
  ) => {
    const colorMap = {
      primary: theme.palette.primary.main,
      secondary: theme.palette.secondary.main,
      info: theme.palette.info.main,
      success: theme.palette.success.main,
      warning: theme.palette.warning.main,
      error: theme.palette.error.main,
      default: "black",
    };

    const color = colorMap[colorName];

    if (!color) {
      throw new Error(`Color ${colorName} is not defined in the theme.`);
    }

    return alpha(color, opacity);
  };

  const handlePrint = () => {
    window.print();
  };

  const renderOverview = () => {
    if (loading) return <LinearProgress />;

    if (error) return <>Something went wrong. Try again later.</>;

    return (
      <PrintLayout>
        <Button
          onClick={handlePrint}
          variant="contained"
          sx={{ mb: 2, displayPrint: "none" }}
        >
          Print Page
        </Button>
        <Box mb={4} display="flex" flexWrap="wrap" gap={6}>
          <Box
            sx={{
              p: 2,
              borderRadius: 2,
              width: 220,
              height: 100,
              boxShadow: "2px 2px 4px 1px rgba(0,0,0,0.2);",
            }}
          >
            <Typography variant="body1" sx={{ mb: 2 }}>
              Total Users
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
              <AccountCircle color="primary" />
              <Typography variant="h4" color="primary">
                {res?.totalUsers}
              </Typography>
            </Box>
          </Box>
          {res?.groupSummary.map((group) => {
            return (
              <Box
                key={group.competitionName}
                sx={(theme) => ({
                  p: 2,
                  borderRadius: 2,
                  height: 100,
                  width: 220,
                  boxShadow: "2px 2px 4px 1px rgba(0,0,0,0.2);",
                })}
              >
                <Typography variant="body1">Total Group</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Competition {group.competitionName}
                </Typography>

                <Box display="flex" alignItems="center" gap={1}>
                  <Groups2 color="primary" />
                  <Typography variant="h4" color="primary">
                    {group.totalGroups}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>

        <Grid container gap={4}>
          <Grid xs={12} sm={12}>
            <Box display="flex" flexWrap="wrap" gap={3}>
              {res?.proposalSummary.map((proposal) => {
                if (!proposal?.proposalsByStatus?.length) return <></>;

                return (
                  <Box
                    display="flex"
                    gap={6}
                    boxShadow="2px 2px 4px 1px rgba(0,0,0,0.2)"
                    p={2}
                    borderRadius={2}
                  >
                    <Box>
                      <Typography variant="h5" mb={4}>
                        Proposal Summary {proposal.competitionName}
                      </Typography>
                      <Box
                        key={proposal.competitionName}
                        maxWidth={240}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Doughnut
                          data={{
                            labels: proposal.proposalsByStatus.map((p) =>
                              normalizeString(p._id, "_")
                            ),
                            datasets: [
                              {
                                label: "Jumlah",
                                data: proposal.proposalsByStatus.map(
                                  (p) => p.count
                                ),
                                backgroundColor: proposal.proposalsByStatus.map(
                                  (p) =>
                                    getColorInRGBA(
                                      generateProposalStatusChipColor(p._id)
                                    )
                                ),
                                hoverOffset: 4,
                              },
                            ],
                          }}
                        />
                        <Typography mt={2}>Status Proposal</Typography>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <Box
                        sx={(theme) => ({
                          width: 220,
                          height: 100,
                        })}
                      >
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Total Proposal
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                          <Article color="primary" />
                          <Typography variant="h4" color="primary">
                            {proposal.totalProposals}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          width: 220,
                          height: 100,
                        })}
                      >
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Total Satuan Kerja Terlibat
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                          <Work color="primary" />
                          <Typography variant="h4" color="primary">
                            {proposal.totalWorkUnits}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={(theme) => ({
                          width: 220,
                          height: 100,
                        })}
                      >
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Total Anggota Terlibat
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                          <AccountCircle color="primary" />
                          <Typography variant="h4" color="primary">
                            {proposal.totalMembers}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Grid>
          <Grid xs={12} sm={12}>
            <Box
              sx={{
                width: 480,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: "2px 2px 4px 1px rgba(0,0,0,0.2)",
                p: 2,
                borderRadius: 2,
                height: "fit-content",
              }}
            >
              <Bar
                data={{
                  labels: res?.workUnitSummary.topWorkUnits.map(
                    (workUnit) => workUnit._id
                  ),
                  datasets: [
                    {
                      label: "Jumlah",
                      data: res?.workUnitSummary.topWorkUnits.map(
                        (workUnit) => workUnit.count
                      ),
                      backgroundColor: "rgba(255, 99, 132, 0.2)",
                      borderColor: "rgb(255, 99, 132)",
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    title: {
                      display: true,
                      text: "Top Satker",
                    },
                  },
                }}
              />
              <Typography>
                Jumlah Satker dengan partisipasi terbanyak
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </PrintLayout>
    );
  };

  return <Box p={4}>{renderOverview()}</Box>;
};

export default OverviewPrintPage;

const PrintLayout = styled("div")(
  () => `
  @media print {
    @page {
      size: landscape;
    }
}
`
);
