import { Description, Close, CloudUpload } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Button,
  Typography,
  styled,
  TextField,
} from "@mui/material";
import { UploadProposalFormInput } from "../../../interfaces/proposal/fe";
import { UseForm } from "../../../hooks/useForm";
import Select from "../../commons/Select";
import { PS_PT } from "../../../constants/pspt";

type UploadProposalFormProps = Pick<
  UseForm<UploadProposalFormInput>,
  "values" | "setValues"
> & {
  errorText: string;
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadProposalForm = (props: UploadProposalFormProps) => {
  const { values, setValues, errorText } = props;

  const handleFileOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    if (file) {
      setValues((val) => ({ ...val, file }));
    }
  };

  const handleRemoveFile = () => {
    setValues((val) => ({ ...val, file: undefined }));
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, py: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {values.file ? (
            <>
              <Description color="primary" /> {values?.file.name}
              <IconButton size="small" onClick={handleRemoveFile}>
                <Close fontSize="small" />
              </IconButton>
            </>
          ) : (
            <>No File Uploaded</>
          )}
        </Box>

        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUpload />}
        >
          Upload file
          <VisuallyHiddenInput type="file" onChange={handleFileOnChange} />
        </Button>

        <Select
          label="PS PT"
          value={values.psPt}
          options={Object.entries(PS_PT).map(([key, value]) => ({
            label: `${key} - ${value}`,
            value: key,
          }))}
          onChange={(value) => {
            setValues((oldValues) => ({
              ...oldValues,
              psPt: value,
            }));
          }}
        />

        <TextField
          label="Pain Point"
          value={values.painPoint}
          multiline
          onChange={(event) => {
            setValues((oldValues) => ({
              ...oldValues,
              painPoint: event.target.value,
            }));
          }}
        />
      </Box>
      {errorText && (
        <Typography variant="caption" color="error">
          {errorText}
        </Typography>
      )}
    </>
  );
};

export default UploadProposalForm;
