import {
  Table as MuiTable,
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Alert,
  TableFooter,
  TablePagination,
  alpha,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { UseTable } from "../../hooks/useTable";
type TableProps = Omit<UseTable, "setPage"> & {
  children: React.ReactNode;
  totalCount: number;
  headers: string[];
  error: boolean;
};
const Table = (props: TableProps) => {
  const {
    error,
    children,
    totalCount,
    headers,
    rowsPerPage,
    rowsPerPageOptions,
    handleChangePage,
    handleChangeRowsPerPage,
    page,
  } = props;
  return (
    <TableContainer component={Paper}>
      <MuiTable>
        <TableHead>
          <TableRow
            sx={(theme) => ({
              backgroundColor: alpha(theme.palette.primary.light, 0.15),
            })}
          >
            {headers.map((header, i) => (
              <TableCell
                key={`table-header-${header}-${i}`}
                sx={{ fontWeight: "bold" }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
          {error && (
            <TableRow>
              <TableCell colSpan={headers.length}>
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">
                    Something went wrong. Try again later.
                  </Alert>
                </Stack>
              </TableCell>
            </TableRow>
          )}
          {!error && totalCount === 0 && (
            <TableRow>
              <TableCell colSpan={headers.length}>
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="info">No Data Found</Alert>
                </Stack>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={error ? [] : rowsPerPageOptions}
              count={error ? 0 : totalCount}
              rowsPerPage={error ? 1 : rowsPerPage}
              page={error ? 0 : page}
              slotProps={{
                select: {
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
